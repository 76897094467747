import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Col, DatePicker, Form, Input, Row, Select, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { omit, pick, values } from 'lodash';
import {
  challengeSharedFields,
  EChallengeTypes,
  IChallenge,
  TChallengeSharedForm,
  TChallengeSingleForm,
  TChallengeTranslation,
} from '../../../ChallengesTypes';
import { asyncActions } from '../../../ChallengesSlice';
import styles from './PersonalForm.module.scss';
import FormCard from '../../../../../components/FormCard/FormCard';
import { UserRoles } from '../../../../../constants/userRoles';
import ImageUploadForm from '../../../../../components/ImageUploadForm/ImageUploadForm';
import ChallengeSingleFormSchema from '../../constants/challengeSingleFormSchema';
import CompanySelector from '../../../../../components/Forms/CompanySelector/CompanySelector';
import { goalTypeUnits } from '../../constants/goalTypeUnits';
import { enableForRoles } from '../../../../../helpers/role';
import { selectAuthenticatedUser } from '../../../../auth/AuthSlice';
import { isActionAvailable } from '../../../ChallengesHelpers';
import { TCompany } from '../../../../collective/companies/CompanyType';
import {
  asyncActions as adminUserAsyncActions,
  selectors as adminUserSelectors,
} from '../../../../adminUsers/AdminUsersAdminSlice';
import { EChallengeStatuses } from '../../../ChallengeList/ChallengeListTypes';
import EChallengeAssignmentTypes from '../../constants/challengeAssignmentTypes';
import ChallengeStatusBadge from '../../../../../components/Badge/ChallengeStatusBadge/ChallengeStatusBadge';
import TranslationTabs from '../../../../../components/TranslationTabs/TranslationTabs';
import TagsInput from '../../../../../components/TagsInput/TagsInput';
import { Config } from '../../../../../config/config';
import { TTag } from '../../../../tags/TagsTypes';
import { selectCurrentLang } from '../../../../i18n/i18nSlice';

const { TextArea } = Input;
const { Option } = Select;

type TPersonalFormProps = {
  currentChallenge: IChallenge | null;
  sharedFields: TChallengeSharedForm | null;
  selectedChallengeLanguage: string;
  onDataChanged: (isDirty: boolean, isValid: boolean) => void;
  onChallengeTypeChange: (
    type: EChallengeTypes,
    sharedFields: TChallengeSharedForm,
  ) => void;
  onSelectStartDate: (startDate?: string | null) => void;
  setSelectedLanguage: (value: string) => void;
};

const defaultValues = {
  type: EChallengeTypes.PERSONAL,
  goalType: 'STEPS',
  calculationMethod: 'SUM',
  steakType: 'NONE',
  enrollmentMethod: 'AUTOMATIC',
  visibility: 'PUBLIC',
};

function PersonalForm(
  {
    currentChallenge,
    sharedFields,
    selectedChallengeLanguage,
    onDataChanged,
    onChallengeTypeChange,
    onSelectStartDate,
    setSelectedLanguage,
  }: TPersonalFormProps,
  ref: Ref<unknown> | undefined,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    onSave,
    isValid,
    isDirty,
  }));

  /* DATA STORE */
  // available categories for current form
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const adminCompanies = useSelector(adminUserSelectors.listData);

  /* STATE */
  const [translations, setTranslations] = useState<TChallengeTranslation[]>([]);
  const [tagList, setTagList] = useState<TTag[]>([]);
  const [tagIds, setTags] = useState<number[]>([]);

  const adminLang = useSelector(selectCurrentLang);

  // form methods
  const personalFormMethods = useForm<TChallengeSingleForm>({
    mode: 'onChange',
    resolver: yupResolver(ChallengeSingleFormSchema),
    defaultValues,
  });
  const {
    reset,
    control,
    formState: { isDirty, isValid },
    setValue,
    watch,
  } = personalFormMethods;
  const data = personalFormMethods.watch();

  /* EFFECTS */
  useEffect(() => {
    const languageLength = Config.I18N.LOCALES.length;
    const rewardNameTLength = translations.filter(
      (item) => item.rewardName !== null && item.rewardName,
    ).length;
    const rewardDescriptionTLength = translations.filter(
      (item) => item.rewardDescription !== null && item.rewardDescription,
    ).length;

    onDataChanged(
      isDirty,
      isValid &&
        ((watch('assignmentType') ===
          EChallengeAssignmentTypes.SELECTED_COMPANIES &&
          !!watch('companies')?.length) ||
          watch('assignmentType') !==
            EChallengeAssignmentTypes.SELECTED_COMPANIES) &&
        tagIds.length > 0 &&
        ((rewardNameTLength === 0 && rewardDescriptionTLength === 0) ||
          (languageLength === rewardNameTLength &&
            languageLength === rewardDescriptionTLength)),
    );
  }, [data]);

  useEffect(() => {
    dispatch<any>(adminUserAsyncActions.fetchList());
  }, [dispatch]);

  useEffect(() => {
    let companies: Array<number> = [];
    let assignmentType = EChallengeAssignmentTypes.ALL;

    if (
      enableForRoles(authenticatedUser.role as UserRoles, [
        UserRoles.COMPANY_MANAGER,
      ])
    ) {
      companies =
        adminCompanies?.list?.map((company: TCompany) => company.id) || [];
      assignmentType = EChallengeAssignmentTypes.SELECTED_COMPANIES;
    }

    setTranslations([]);
    reset({
      ...defaultValues,
      ...sharedFields,
      assignmentType,
      companies,
    });

    onLanguageTab('en');
  }, []);

  const preloadData = () => {
    if (currentChallenge?.challengeTranslations) {
      setTranslations(currentChallenge.challengeTranslations);
    }
    if (currentChallenge?.tags) {
      setTags(currentChallenge.tags.map((tag) => tag.id));
    }
  };

  useEffect(() => {
    if (currentChallenge) {
      preloadData();
      reset(
        omit(
          {
            ...currentChallenge,
            goalValue: goalTypeUnits[
              currentChallenge.goalType || 'STEP'
            ]?.refunc(currentChallenge.goalValue || 0),
            companies: currentChallenge.companies?.map(({ id }) => id),
            assignmentType: (() => {
              if (currentChallenge.isCompanyOnly) {
                return EChallengeAssignmentTypes.COMPANIES_ONLY;
              }
              if (currentChallenge.companies?.length) {
                return EChallengeAssignmentTypes.SELECTED_COMPANIES;
              }
              return EChallengeAssignmentTypes.ALL;
            })(),
            imageBase64: currentChallenge.titleImage || '',
            capValue: currentChallenge.isCapped
              ? currentChallenge.capValue
              : undefined,
            isSpecificLeaderboard: currentChallenge.isSpecificLeaderboard,
          },
          [
            'id',
            'createdAt',
            'updatedAt',
            'activityTag',
            'status',
            'titleImage',
            'bySuperAdmin',
            'enrolledUsersNumber',
          ],
        ),
      );
    } else {
      reset({
        ...defaultValues,
        ...sharedFields,
      });
    }
  }, [currentChallenge]);

  useEffect(() => {
    setValue('translations', translations);
    onLanguageTab(selectedChallengeLanguage);
  }, [translations]);

  useEffect(() => {
    setValue('tagIds', tagIds);
  }, [tagIds]);

  useEffect(() => {
    if (sharedFields?.translations) {
      setTranslations(sharedFields.translations);
    }
    if (sharedFields?.tagIds) {
      setTags(sharedFields.tagIds);
    }
  }, [sharedFields]);

  /* METHODS */
  const updateAndPublish = (formData: FormData) => {
    if (!currentChallenge?.id) {
      return;
    }

    if (
      ![EChallengeStatuses.DRAFT, EChallengeStatuses.INACTIVE].includes(
        currentChallenge.status.title,
      )
    ) {
      dispatch<any>(
        asyncActions.update({ id: currentChallenge.id, data: formData }),
      );
    } else {
      dispatch<any>(
        asyncActions.updateAndPublish({
          id: currentChallenge.id,
          data: formData,
        }),
      );
    }
  };

  const updateAndDraft = (formData: FormData) => {
    if (!currentChallenge?.id) {
      return;
    }

    if (currentChallenge.status.title === EChallengeStatuses.DRAFT) {
      dispatch<any>(
        asyncActions.update({ id: currentChallenge.id, data: formData }),
      );
    } else {
      dispatch<any>(
        asyncActions.updateAndDraft({
          id: currentChallenge.id,
          data: formData,
        }),
      );
    }
  };

  const onSave = (publish?: boolean) => {
    const formData = new FormData();

    const translatedFields = {
      tagIds: tagIds,
      challengesTranslations: translations.map((translation) => {
        const { id, ...newTranslation } = translation;
        return newTranslation;
      }),
    };

    Object.keys(translatedFields).forEach((k: string) => {
      const field = k as keyof typeof translatedFields;
      if (field === 'tagIds') {
        translatedFields.tagIds.forEach((aTagId, index) =>
          formData.append(`${k}[${index}]`, aTagId.toString()),
        );
      } else if (field === 'challengesTranslations') {
        translatedFields.challengesTranslations.forEach(
          (translation, index) => {
            formData.append(`${k}[${index}]`, JSON.stringify(translation));
          },
        );
      }
    });

    Object.keys(data).forEach((k: string) => {
      const key = k as
        | keyof TChallengeSingleForm
        | 'tags'
        | 'isCapped'
        | 'translations';

      const skipFields = ['imageBase64'];

      if (
        !data[key as keyof TChallengeSingleForm] ||
        skipFields.includes(key)
      ) {
        return;
      }

      switch (key) {
        case 'assignmentType': {
          if (formData.has('isCompanyOnly')) {
            formData.delete('isCompanyOnly');
          }
          formData.append(
            'isCompanyOnly',
            (data[key] === EChallengeAssignmentTypes.COMPANIES_ONLY).toString(),
          );
          break;
        }
        case 'goalValue': {
          formData.append(
            'goalValue',
            goalTypeUnits[data.goalType || 'STEPS']
              ?.func(data[key] || 0)
              .toString(),
          );
          break;
        }
        case 'image': {
          formData.append('titleImage', data[key] as Blob);
          break;
        }
        case 'companies': {
          if (
            data.assignmentType !== EChallengeAssignmentTypes.SELECTED_COMPANIES
          ) {
            return;
          }

          (data[key] as TChallengeSingleForm['companies'])?.forEach((c) => {
            formData.append('companiesIds[]', c.toString());
          });
          break;
        }
        case 'startDate':
        case 'endDate': {
          formData.append(
            key,
            data[key] ? dayjs(data[key]).format('YYYY-MM-DD') : '',
          );
          break;
        }
        default:
          if (
            key !== 'challengeTranslations' &&
            key !== 'tags' &&
            key !== 'tagIds' &&
            key !== 'isCapped' &&
            key !== 'translations'
          )
            formData.append(
              key,
              data[key as keyof TChallengeSingleForm]?.toString() || '',
            );
      }
    });
    if (currentChallenge?.id) {
      if (publish) {
        formData.append('language', adminLang || Config.I18N.DEFAULT_LANGUAGE);
        updateAndPublish(formData);
      } else {
        updateAndDraft(formData);
      }
    } else if (publish) {
      dispatch<any>(asyncActions.createAndPublish({ data: formData }));
    } else {
      dispatch<any>(asyncActions.create({ data: formData }));
    }

    reset();
  };

  const onLanguageTab = (appLanguage: string) => {
    setValue(
      'title',
      translations.find((item) => item.language === appLanguage)?.title,
    );
    setValue(
      'description',
      translations.find((item) => item.language === appLanguage)?.description,
    );
    setValue(
      'rewardName',
      translations.find((item) => item.language === appLanguage)?.rewardName,
    );
    setValue(
      'rewardDescription',
      translations.find((item) => item.language === appLanguage)
        ?.rewardDescription,
    );

    setSelectedLanguage(appLanguage);
  };

  const updateTranslation = (
    key: 'title' | 'description' | 'rewardName' | 'rewardDescription',
    value: string,
  ) => {
    setTranslations((translations) => {
      const updatedTranslations = [...translations];
      if (
        translations.length > 0 &&
        translations.find(
          (translation) => translation.language === selectedChallengeLanguage,
        )
      ) {
        updatedTranslations.map((item, index) => {
          if (item.language === selectedChallengeLanguage) {
            updatedTranslations[index] = {
              ...updatedTranslations[index],
              [key]: value && value !== '' ? value : null,
            };
          }
        });
      } else {
        updatedTranslations.push({
          [key]: value && value !== '' ? value : null,
          language: selectedChallengeLanguage,
        });
      }
      return updatedTranslations;
    });
    setValue(key, value);
  };

  const emptyTranslations = () => {
    return Config.I18N.LOCALES.reduce((acc, current) => {
      acc[current] = '';
      return acc;
    }, {} as { [key: string]: string });
  };

  const canPerformUpdate = isActionAvailable(
    authenticatedUser?.role,
    adminCompanies?.list?.map((company: TCompany) => company.id),
    currentChallenge?.companies?.map((company) => company.id),
  );

  const daysLeft = currentChallenge
    ? Math.round(dayjs(currentChallenge?.endDate).diff(dayjs(), 'days', true))
    : 0;

  return (
    <div className={styles.formWrapper}>
      <div className={styles.form}>
        {currentChallenge?.status.title === EChallengeStatuses.FINISHED && (
          <Alert
            className={styles.alert}
            message={t('challenges.form.alertFinishedMessage')}
            type="info"
            showIcon
          />
        )}
        {currentChallenge?.status.title === EChallengeStatuses.ONGOING && (
          <Alert
            className={styles.alert}
            message={t('challenges.form.alertOngoingMessage')}
            type="info"
            showIcon
          />
        )}
        <FormProvider {...personalFormMethods}>
          <Form className={styles.form} layout="vertical">
            <FormCard className={styles.card}>
              <>
                <div id="basic-info" className={styles.title}>
                  {t('challenges.form.addBasicInfo')}
                </div>
                <div className={styles.translationsTabs}>
                  <TranslationTabs
                    selectedLanguage={selectedChallengeLanguage}
                    onLanguageTab={onLanguageTab}
                  />
                </div>
                <Row>
                  <Col xs={24} md={11}>
                    <Form.Item
                      label={t('challenges.form.challengeName')}
                      tooltip={{
                        title: t('challenges.form.challengeName'),
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            onChange={(e) =>
                              updateTranslation('title', e.target.value)
                            }
                            className={styles.inputWithLengthCounter}
                            disabled={!canPerformUpdate}
                            defaultValue={
                              sharedFields?.translations?.find(
                                (item) =>
                                  item.language === selectedChallengeLanguage,
                              )?.title
                            }
                            placeholder={
                              t('challenges.placeholder.challengeName') || ''
                            }
                            maxLength={50}
                            showCount
                          />
                        )}
                      />
                    </Form.Item>
                    <Form.Item label={t('challenges.form.description')}>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <TextArea
                            {...field}
                            onChange={(e) =>
                              updateTranslation('description', e.target.value)
                            }
                            disabled={!canPerformUpdate}
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            showCount
                            maxLength={400}
                          />
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles.formItem}
                      label={t('insights.edit.tags')}
                    >
                      <TagsInput
                        selectedInsightLanguage={selectedChallengeLanguage}
                        tags={tagIds}
                        tagList={tagList}
                        emptyTranslations={emptyTranslations}
                        setTags={setTags}
                        setTagList={setTagList}
                      />
                    </Form.Item>
                    <Row>
                      <Col xs={24} sm={11}>
                        <Form.Item
                          className={styles.typeContainer}
                          label="Play mode"
                        >
                          <Select
                            disabled={!canPerformUpdate}
                            showSearch
                            defaultValue={EChallengeTypes.PERSONAL}
                            className={styles.selectField}
                            onSelect={(val: EChallengeTypes) => {
                              onChallengeTypeChange(
                                val,
                                pick(data, challengeSharedFields),
                              );
                            }}
                          >
                            <Option value={EChallengeTypes.PERSONAL}>
                              {t('challenges.form.individual')}
                            </Option>
                            <Option value={EChallengeTypes.TEAM}>
                              {t('challenges.form.byTeam')}
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={2} />
                  <Col xs={24} md={11}>
                    <ImageUploadForm
                      onFileChanged={(img, base64) => {
                        setValue('image', img || undefined);
                        setValue('imageBase64', base64 || undefined, {
                          shouldValidate: true,
                        });
                      }}
                      currentImage={data.imageBase64}
                      tooltip={t('challenges.tooltips.imageResolution')}
                      disabled={!canPerformUpdate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={11}>
                    <Form.Item label={t('challenges.form.modalType')}>
                      <Controller
                        name="goalType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            disabled={!canPerformUpdate}
                            showSearch
                          >
                            <Option value="DISTANCE">
                              {t('generic.distance')}
                            </Option>
                            <Option value="CALORIES">
                              {t('generic.calories')}
                            </Option>
                            <Option value="DURATION">
                              {t('generic.duration')}
                            </Option>
                            <Option value="PHOTOS">
                              {t('generic.picture')}
                            </Option>
                            <Option value="STEPS">{t('generic.steps')}</Option>
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} />
                  <Col xs={24} sm={11}>
                    <Form.Item
                      label={t('challenges.form.goalValue')}
                      tooltip={{
                        title: t('challenges.form.goalValue'),
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Controller
                        name="goalValue"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={!canPerformUpdate}
                            addonAfter={t(
                              goalTypeUnits[data.goalType || 'POINTS']?.name,
                            )}
                            type="number"
                            value={data.goalValue}
                            onWheel={(e) =>
                              (
                                e.target as unknown as { blur: () => void }
                              ).blur()
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={11}>
                    <Form.Item
                      label={t('challenges.form.capping')}
                      tooltip={{
                        title: t('challenges.form.capping'),
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Controller
                        name="capValue"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={!canPerformUpdate}
                            addonAfter={
                              data.goalType === 'DISTANCE'
                                ? 'm'
                                : t(
                                    goalTypeUnits[data.goalType || 'STEPS']
                                      ?.name,
                                  )
                            }
                            type="number"
                            value={data.capValue}
                            onWheel={(e) =>
                              (
                                e.target as unknown as { blur: () => void }
                              ).blur()
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className={styles.title}>
                  {t('challenges.form.challengeAssignment.title')}
                </div>
                <CompanySelector
                  key={`assignment-${data?.assignmentType}`}
                  onSelectCompanies={(ids, companiesOnly, assignmentType) => {
                    setValue('companies', ids);
                    setValue('assignmentType', assignmentType);
                  }}
                  selectedCompanyIds={watch('companies')}
                  onlySelected={enableForRoles(authenticatedUser.role, [
                    UserRoles.COMPANY_MANAGER,
                  ])}
                  disabled={
                    !canPerformUpdate ||
                    ![
                      EChallengeStatuses.DRAFT,
                      EChallengeStatuses.UPCOMING,
                    ].includes(
                      currentChallenge?.status.title ||
                        EChallengeStatuses.DRAFT,
                    )
                  }
                  isCompaniesOnly={!!data.isCompanyOnly}
                />
                <Form.Item
                  className={styles.formItem}
                  label={t('challenges.form.isSpecificLeaderboard')}
                >
                  <Controller
                    name="isSpecificLeaderboard"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        disabled={!canPerformUpdate}
                        checked={data.isSpecificLeaderboard}
                        onChange={(val) => {
                          setValue('isSpecificLeaderboard', val);
                        }}
                      />
                    )}
                  />
                </Form.Item>
                <div className={styles.title}>
                  {t('challenges.form.rewardTitle') || ''}
                </div>
                <div className={styles.subTitle}>
                  {t('challenges.form.rewardSubtitle')}
                </div>
                <Row>
                  <Col xs={24} sm={11}>
                    <Form.Item
                      label={t('rewards.rewardName')}
                      tooltip={{
                        title: 'Reward name',
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Controller
                        name="rewardName"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            maxLength={50}
                            showCount
                            placeholder={
                              t('challenges.placeholder.challengeName') || ''
                            }
                            disabled={!canPerformUpdate}
                            onChange={(e) =>
                              updateTranslation('rewardName', e.target.value)
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={11}>
                    <Form.Item label={t('challenges.form.rewardDescription')}>
                      <Controller
                        name="rewardDescription"
                        control={control}
                        render={({ field }) => (
                          <TextArea
                            {...field}
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            showCount
                            maxLength={200}
                            disabled={!canPerformUpdate}
                            onChange={(e) =>
                              updateTranslation(
                                'rewardDescription',
                                e.target.value,
                              )
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className={styles.title}>
                  {t('challenges.form.configureParameters')}
                </div>
                <Row>
                  <Col xs={24} sm={11}>
                    <Form.Item
                      label={t('challenges.form.startDate')}
                      style={{ width: '100%' }}
                    >
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            disabled={!canPerformUpdate}
                            onChange={(val) => {
                              setValue(
                                'startDate',
                                val?.toISOString() || undefined,
                              );
                              onSelectStartDate(val?.toISOString());
                            }}
                            placeholder={t('generic.selectDate') || ''}
                            className={styles.dateInput}
                            value={
                              data.startDate ? dayjs(data.startDate) : null
                            }
                            disabledDate={(current) => {
                              const customDate = dayjs().format('YYYY-MM-DD');
                              return (
                                current &&
                                current < dayjs(customDate, 'YYYY-MM-DD')
                              );
                            }}
                            onClick={() => {
                              !data.startDate &&
                                setValue(
                                  'startDate',
                                  dayjs().format('YYYY-MM-DD'),
                                );
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} />
                  <Col xs={24} sm={11}>
                    <Form.Item label={t('challenges.form.endDate')}>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            disabled={!canPerformUpdate}
                            placeholder={t('generic.selectDate') || ''}
                            className={styles.dateInput}
                            onChange={(val) =>
                              setValue(
                                'endDate',
                                val?.toISOString() || undefined,
                              )
                            }
                            value={data.endDate ? dayjs(data.endDate) : null}
                            disabledDate={(current) => {
                              const customDate = data.startDate
                                ? dayjs(data.startDate).format('YYYY-MM-DD')
                                : dayjs().format('YYYY-MM-DD');
                              return (
                                current &&
                                current < dayjs(customDate, 'YYYY-MM-DD')
                              );
                            }}
                            onClick={() => {
                              !data.endDate &&
                                setValue(
                                  'endDate',
                                  data.startDate
                                    ? dayjs(data.startDate).format('YYYY-MM-DD')
                                    : dayjs().format('YYYY-MM-DD'),
                                );
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            </FormCard>
          </Form>
        </FormProvider>
      </div>
      <div className={styles.dataColumn}>
        <div>{t('challenges.form.challengeInformation')}</div>
        <div className={styles.dataBlock}>
          <div>{t('articles.currentStatus.title')}</div>
          {currentChallenge?.status.title === EChallengeStatuses.ONGOING ? (
            <div className={styles.statusWrapper}>
              <ChallengeStatusBadge
                status={
                  currentChallenge?.status.title || EChallengeStatuses.DRAFT
                }
              />
              &nbsp;-&nbsp;
              {(daysLeft > 1 || daysLeft < 1) && (
                <div>
                  {t('challenges.form.leaderboard.daysLeft', {
                    count: daysLeft,
                  })}
                </div>
              )}
              {daysLeft === 1 && (
                <div>{t('challenges.form.leaderboard.dayLeft')}</div>
              )}
            </div>
          ) : (
            <ChallengeStatusBadge
              status={
                currentChallenge?.status.title || EChallengeStatuses.DRAFT
              }
            />
          )}
        </div>
        <div className={styles.dataBlock}>
          <div>{t('articles.id.title')}</div>
          <div className={styles.dataBlockValue}>
            {currentChallenge?.id || '-'}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div>{t('articles.created.title')}</div>
          <div className={styles.dataBlockValue}>
            {currentChallenge?.createdAt
              ? dayjs(currentChallenge?.createdAt).format('DD/MM/YYYY HH:mm')
              : '-'}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div>{t('articles.updated.title')}</div>
          <div className={styles.dataBlockValue}>
            {currentChallenge?.updatedAt
              ? dayjs(currentChallenge?.updatedAt).format('DD/MM/YYYY HH:mm')
              : '-'}
          </div>
        </div>
      </div>
    </div>
  );
}
export default forwardRef(PersonalForm);
