import { IPagination } from '../general/GeneralTypes';
import { TCategory } from '../categories/CategoriesTypes';
import { TActivityTag } from '../activityTags/ActivityTagsTypes';
import { TCompany } from '../collective/companies/EditCompany/screens/Admins/CompanyAdminsType';
import { EChallengeStatuses } from './ChallengeList/ChallengeListTypes';
import { TUser } from '../users/UsersTypes';
import EChallengeAssignmentTypes from './EditChallenge/constants/challengeAssignmentTypes';
import { TTag } from '../tags/TagsTypes';

export type TChallengeStatus = {
  id: number;
  title: EChallengeStatuses;
};

export enum EGoalTypes {
  POINTS = 'POINTS',
  DISTANCE = 'DISTANCE',
  CALORIES = 'CALORIES',
  DURATION = 'DURATION',
  STEPS = 'STEPS',
  PHOTOS = 'PHOTOS',
}

export interface IChallenge {
  title: string;
  type: EChallengeTypes;
  activityType: string[];
  startDate: string;
  repetition: string;
  goalType: EGoalTypes;
  goalValue: number;
  goalCap: boolean;
  calculationMethod: string;
  enrollmentMethod: string;
  steakType: string;
  visibility: string;
  wefitterId?: string;
  rewardInPoints?: number;
  endDate?: string;
  titleImage?: string;
  publishDate?: string;
  unpublishDate?: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  activityTagId?: number;
  categoryId?: number;
  category?: TCategory;
  activityTag?: TActivityTag;
  entity?: string;
  companies?: TCompany[];
  status: TChallengeStatus;
  companiesIds?: number[];
  enrolledUsersNumber: number;
  isCompanyOnly: boolean;
  workout?: boolean;
  capValue?: string;
  tags?: TTag[];
  challengeTranslations?: TChallengeTranslation[];
  isSpecificLeaderboard?: boolean;
  isCapped?: boolean;
}

export interface IFetchChallengesResponse {
  success: boolean;
  data: IChallenge[];
  pagination: IPagination;
}

export type TNewChallengeResponse = {
  statusCode: number;
  message?: string[];
  error?: string;
  success?: boolean;
  data: IChallenge;
};

export type TSearchRequest = { [key: string]: string }[];

export const challengeSharedFields = [
  'title',
  'desciption',
  'translations',
  'tagIds',
  'capValue',
  'startDate',
  'endDate',
  'image',
  'imageBase64',
  'goalType',
  'companies',
  'assignmentType',
  'goalValue',
];

export type TChallengeTranslation = {
  id?: number;
  language?: string;
  title?: string;
  description?: string;
  rewardName?: string;
  rewardDescription?: string;
  goalText?: string;
};

export type TChallengeSharedForm = {
  title?: TChallengeTranslation['title'];
  description?: TChallengeTranslation['description'];
  startDate?: string;
  endDate?: string;
  image?: File;
  imageBase64?: string;
  goalType?: string;
  activityType?: string[];
  isCompanyOnly?: boolean;
  // assign companies
  companies?: number[];
  assignmentType?: EChallengeAssignmentTypes;
  capValue?: string;
  tagIds?: number[];
  challengeTranslations?: TChallengeTranslation[];
  rewardName?: string;
  rewardDescription?: string;
  isSpecificLeaderboard?: boolean;
  translations?: TChallengeTranslation[];
};

export type TChallengeSingleForm = {
  type?: string;
  repetition?: string;
  goalValue: number;
  calculationMethod?: string;
  steakType?: string;
  goalCap?: boolean;
  enrollmentMethod?: string;
  visibility?: string;
} & TChallengeSharedForm;

export type TChallengeTeamForm = {
  //type?: string;
  teamSize?: number;
  goalText?: string;
} & TChallengeSharedForm;

export enum EChallengeTypes {
  PERSONAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  TEAM = 'TEAM',
}

export type TEnrolledUser = {
  id: number;
  challengeId: number;
  userId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  seenAt: string;
  user: TUser;
};

export type TEnrolledUserListResponse = {
  pagination: IPagination;
  data: TEnrolledUser[];
};

export type TTopTeam = {
  id: number;
  name: string;
  total: number;
  total_workout_calories_progress?: number;
  total_workout_distance_progress?: number;
  total_workout_duration_progress?: {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  };
  total_workout_steps_progress?: number;
};

export type TTopTeamListResponse = {
  data: TTopTeam[];
};

export type TTeamUserListResponse = {
  data: TUser[];
  pagination: IPagination;
};
