import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import BackgroundWave from '../../../components/BackgoundWave/BackgroundWave';
import SideBackgroundImage from '../../../components/SideBackgroudImage/SideBackgroundImage';
import CentralCard from '../../../components/CentralCard/CentralCard';
import styles from './NewUserConfirmation.module.scss';
import { ROUTES } from '../../../constants/routes';

const { Item } = Form;

function NewUserConfirmation() {
  const { t } = useTranslation();

  const onAgree = () => {};

  // const window size
  const isMobile = window.screen.width < 600;

  return (
    <div className={styles.container}>
      <BackgroundWave>
        <SideBackgroundImage
          leftImage={!isMobile ? '/assets/auth/fox.svg' : ''}
          rightImage={!isMobile ? '/assets/auth/payment.svg' : ''}
        >
          <div className={isMobile ? styles.wrapMobile : styles.wrap}>
            {!isMobile ? (
              <Link to="/" className={styles.logoContainer}>
                <ReactSVG
                  className={styles.logo}
                  src="/assets/general/logo.svg"
                />
                <div className={styles.logoText}>Vitaance app</div>
              </Link>
            ) : (
              <Link to="/" className={styles.logoContainerMobile}>
                <ReactSVG
                  className={styles.logoMobile}
                  src="/assets/auth/logoMobile.svg"
                />
              </Link>
            )}
            <CentralCard
              className={
                isMobile ? styles.centralCardMobile : styles.centralCard
              }
            >
              <>
                <ReactSVG src="/assets/auth/new-user-success.svg" />
                <div className={styles.title}>
                  {t('newUserconfirmation.title')}
                </div>
                <div className={styles.helpText}>
                  {t('newUserconfirmation.helpText')}
                </div>
                <Form className={styles.form} layout="vertical">
                  <Item>
                    <Button
                      onClick={onAgree}
                      htmlType="submit"
                      className={styles.signInButton}
                      type="primary"
                      href={ROUTES.newLogin}
                    >
                      {t('newUserconfirmation.button')}
                    </Button>
                  </Item>
                </Form>
              </>
            </CentralCard>
            <div
              className={isMobile ? styles.legalBlockMobile : styles.legalBlock}
            >
              <div className={styles.questions}>
                {t('generic.haveQuestions')}
                {' '}
                <a
                  href="https://www.vitaance.com/sobre-nosotros"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>{t('generic.visitWeb')}</u>
                </a>
              </div>
              <div>{t('generic.copyright')}</div>
              <div>
                <a href="https://www.vitaance.com/aviso-legal" target="_blank" rel="noreferrer">
                  {t('generic.legalDocs')}
                </a>
                {' '}
                |
                {' '}
                <a
                  href="https://www.vitaance.com/politica-de-privacidad-vitaance"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('generic.privacyPolicy')}
                </a>
              </div>
            </div>
          </div>
        </SideBackgroundImage>
      </BackgroundWave>
    </div>
  );
}

export default NewUserConfirmation;
